import { graphql, PageProps } from "gatsby";
import React, { memo } from "react"
import { DeckLayout } from "../components/layout/deck-layout";

const IndexPage: React.FC<PageProps<GatsbyTypes.Query>> = memo(({data}) => (<DeckLayout data={data}/>))

export default IndexPage
export const query = graphql`
query {
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    nodes {
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
      id
      body
      slug
    }
    distinct(field: frontmatter___tags)
  }
}
`

// const RecommendationCard: React.FC<GatsbyTypes.Mdx> = ( mdx ) => {
  
//   return(
//   <Grid item xs={12}>
//     <StyledCard sx={{width: '100%', height: 'auto'}}>
//       <StyledCardActionArea>
//         <RecommendationCardImage
//           component="img"
//           image="/images/icon.png"
//           alt=""
//         />
//         <StyledRecommendationCardContent>
//           <CardTitle>
//             {text ? text : 'タイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトル'}
//           </CardTitle>
//           <CardDate>
//             日時日時日時日時日時日時日時日時日時日時日時日時日時日時日時日時日時日時
//           </CardDate>
//           <CardDescription>
//             テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
//           </CardDescription>
//         </StyledRecommendationCardContent>
//       </StyledCardActionArea>
//     </StyledCard>
//   </Grid>
// )}